import React, {useContext, useState} from 'react';
import UserContext from "../context/user";
import {findEmployee} from "../../employees";
import {TypeForm} from "../components/typeform";

export const ReportReviewForm = (props) => {  
  const user = useContext(UserContext)

  if(!props.report) {
    return null
  } else {
    const manager = findEmployee(props.report.manager)

    const formId = 'https://certainaffinity.typeform.com/to/P8EZM81K?&alias='+user.employee.id+'&manageralias='+manager.id+'&department='+props.report.department+'&discipline='+props.report.discipline+'&location='+props.report.location+'&reportalias='+props.report.id+'&report='+props.report.name+'&reportnickname='+props.report.nickname
  
    return(
    	<>
  		<TypeForm
        formId={formId}
        onSubmit={() => {
        	console.log("Report review complete")
        }}
  	  />
      </>
    )
  }
}
